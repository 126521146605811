import { PickAsRequired } from "@tanstack/react-router";
import api from "../../../../utils/api";
import { NotFoundError } from "../../../../utils/errors";
import { AddressType, CompanyType } from "../../../../utils/types";

export const fetchCompanyById = async (id: number | string) => {
  const company = await api
    .get(`companies/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      if (err.response.status === 404) {
        throw new NotFoundError(`Företag med id "${id}" hittades inte!`);
      }
      throw err;
    });

  return company;
};

export async function updateCompany({
  id,
  ...updated
}: PickAsRequired<Partial<CompanyType>, "id">) {
  const result = await api
    .put(`companies/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function updateCompanyAddress({
  id,
  companyId,
  ...updated
}: PickAsRequired<Partial<AddressType>, "id">) {
  const result = await api
    .put(`companies/${companyId}/address`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
}

export async function addLogo({
  companyId,
  formData,
}: {
  companyId: number;
  formData: FormData;
}) {
  const response = await api
    .post(`companies/${companyId}/logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}

export async function deleteLogo({
  companyId,
  imageId,
}: {
  companyId: number;
  imageId: number;
}) {
  const response = await api
    .delete(`companies/${companyId}/logo/${imageId}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}