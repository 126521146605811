import { Suspense, useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { Button, Dialog } from "@pnpm-monorepo/core/src/ui/components";
import { FallbackImage, Image } from "../../../../utils/renderers";

import { useDeleteOfferImage, useAddOfferImage } from "../-api/queryOptions";
import { MdAdd, MdDelete } from "@pnpm-monorepo/core/src/ui/icons";
import { OfferType } from "../../../../utils/types";
import ImageUploader from "../../../../components/ImageUploader";

export function ImageFields({ offer }: { offer: OfferType }) {
  const { images } = offer;

  return (
    <>
      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Erbjudandebild
        </div>
        <div className="text-body-medium py-2">
          <div className="pt-2 flex  gap-4">
            <AddImage offerId={offer.id} />
            {images?.length ? (
              <RemoveImage offerId={offer.id} imageId={images[0].id} />
            ) : null}
          </div>

          <div className="overflow-hidden mt-2">
            <Suspense fallback={<FallbackImage className="object-cover" />}>
              <Image
                url={images?.length ? images[0].url : ""}
                className="object-cover"
              />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

const AddImage = ({ offerId }: { offerId: number; }) => {
  const [open, setOpen] = useState<boolean>(false);
  const addOfferImage = useAddOfferImage(offerId);

  function handleAddImage(): void {
    setOpen(true);
  }

  function handleOk(blob: Blob, image: File): void {
    const file = new File([blob], image.name, { type: image.type });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("offerId", offerId.toString());

    addOfferImage.mutate(
      {
        offerId,
        formData,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  }

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Button
        variant="tonal"
        size="small"
        iconLeft={<MdAdd size={18} />}
        onClick={handleAddImage}
      >
        Välj en bild
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        headline="Lägg till bild"
        icon={<MdAdd />}
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
          </>
        }
      >
        <div className="flex flex-col justify-center items-center">
          <ImageUploader onImageUpload={handleOk} isPending={addOfferImage.isPending} thumbnail={true} />
        </div>
      </Dialog>
    </>
  );
};

const RemoveImage = ({
  offerId,
  imageId,
}: {
  offerId: number;
  imageId: number;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const deleteOfferImage = useDeleteOfferImage(offerId, imageId);

  function handleDeleteImage(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOk(): void {
    deleteOfferImage.mutate(
      {
        offerId: offerId,
        imageId: imageId,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  }

  return (
    <>
      <Button
        variant="tonal"
        size="small"
        iconLeft={<MdDelete size={18} />}
        onClick={handleDeleteImage}
      >
        Ta bort befintlig bild
      </Button>
      <Dialog
        icon={<MdDelete />}
        open={open}
        onClose={handleClose}
        headline="Ta bort befintlig bild?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={deleteOfferImage.isPending}
            >
              Ok
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};
