import api from "../../../../../utils/api";

export const fetchCompanyOffers = async (id: number | string) => {
  const companyOffers = await api
    .get(`companies/${id}/offers`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return companyOffers;
};

export async function addOffer({ companyId }: { companyId: number | string }) {
  const response = await api
    .post(`companies/${companyId}/offers`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
}
