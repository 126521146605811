"use client";
import {
  Tooltip as InternalTooltip,
  TooltipContent as InternalTooltipContent,
  TooltipTrigger as InternalTooltipTrigger,
} from "./internal/Tooltip";

import { TooltipProps } from "./Tooltip.types";

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { content, children } = props;

  return (
    <InternalTooltip>
      <InternalTooltipTrigger>{children}</InternalTooltipTrigger>
      <InternalTooltipContent className="bg-inverse-surface text-inverse-on-surface text-body-small rounded-extra-small box-border w-max max-w-[calc(100vw_-_10px)] px-2 py-1">
        {content}
      </InternalTooltipContent>
    </InternalTooltip>
  );
};

export default Tooltip;
