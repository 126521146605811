import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { consumerEventsQueryOptions } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { FilterChip, Table } from "@pnpm-monorepo/core/src/ui/components";
import { NoWrap, SmallDateTimeString } from "../../../../utils/renderers";
import { OfferEventType } from "../../../../utils/types";
import { EventActions } from "./-components/EventActions";

export const Route = createFileRoute("/consumers/$consumerId/events")({
  component: ConsumerEventsComponent,
});

function Result({ events }: { events: OfferEventType[] }) {
  return events.length ? (
    <>
      <Table.Wrapper>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Tidpunkt</Table.HeadCell>
              <Table.HeadCell>Händelse</Table.HeadCell>
              <Table.HeadCell>Område</Table.HeadCell>
              <Table.HeadCell>Företag</Table.HeadCell>
              <Table.HeadCell className="">Butik</Table.HeadCell>
              <Table.HeadCell className="">Erbjudande</Table.HeadCell>
              <Table.HeadCell className="">Erbjudandetext</Table.HeadCell>
              <Table.HeadCell className="">Inflyttad kod</Table.HeadCell>
              <Table.HeadCell className="border-r-0">Inflyttad namn</Table.HeadCell>
              <Table.HeadCell className="sticky right-0 bg-surface-container-highest shadow-sticky-left"></Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {events.map((event, index: number) => {
              return (
                <Table.Row key={index}>
                  <Table.RowCell>
                    <NoWrap>
                      <SmallDateTimeString value={event.createdAt} />
                    </NoWrap>
                  </Table.RowCell>
                  <Table.RowCell>{event.event}</Table.RowCell>
                  <Table.RowCell>{event.placeName}</Table.RowCell>
                  <Table.RowCell>{event.companyName}</Table.RowCell>
                  <Table.RowCell>
                    {event.storeName}
                  </Table.RowCell>
                  <Table.RowCell>
                    {event.offerNumber}
                  </Table.RowCell>
                  <Table.RowCell>
                    {event.offerText}
                  </Table.RowCell>
                  <Table.RowCell>
                    {event.receiverCode}
                  </Table.RowCell>
                  <Table.RowCell className="border-r-0">
                    {event.receiverName}
                  </Table.RowCell>
                  <Table.RowCell
                    justify="justify-center"
                    className=" sticky right-0 bg-surface overflow-hidden shadow-sticky-left"
                  >
                    {event.event === "Inlöst" ? (
                      <>
                        <EventActions event={event} />
                      </>
                    ) : null}
                  </Table.RowCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </Table.Wrapper>
    </>
  ) : (
    <div className="p-3 bg-tertiary-container text-on-tertiary-container mt-6 text-center rounded-large text-body-large">
      Det finns inga konsumenthändelser.
    </div>
  );
}

function ConsumerEventsComponent() {
  const params = Route.useParams();
  const defaultEventFilter = React.useMemo(() => [1, 2, 3], []);
  const [events, setEvents] = React.useState(defaultEventFilter);

  const handleEventClick = (id: number) => {
    const currentIndex = events.indexOf(id);
    const newChecked = [...events];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setEvents(newChecked);
  };

  const { data, isPending } = useQuery(
    consumerEventsQueryOptions(params.consumerId, events)
  );

  return (
    <div className="flex flex-col lg:flex-row w-full">
      <div className="flex flex-col grow">
        <div className="flex pt-4 pl-4 gap-2 justify-start items-center">
          <FilterChip
            onClick={() => handleEventClick(1)}
            selected={events.indexOf(1) !== -1}
          >
            Öppnat
          </FilterChip>
          <FilterChip
            onClick={() => handleEventClick(2)}
            selected={events.indexOf(2) !== -1}
          >
            Sparat
          </FilterChip>
          <FilterChip
            onClick={() => handleEventClick(3)}
            selected={events.indexOf(3) !== -1}
          >
            Inlöst
          </FilterChip>
        </div>
        <div className="flex flex-col w-full p-4 text-body-medium">
          {isPending ? (
            <DefaultPendingComponent />
          ) : (
            <>
              <Result events={data} />
            </>
          )}
          <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
        </div>
      </div>
    </div>
  );
}
