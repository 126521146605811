import { queryOptions, useMutation } from "@tanstack/react-query";
import { SortOrder, StatusType } from "../../../utils/types";
import { fetchCampaigns, CampaignsSortBy, createCampaign } from "./campaigns";
import { queryClient } from "../../../App";

export const campaignsQueryOptions = (opts: {
  q?: string;
  page?: number;
  sortBy?: CampaignsSortBy;
  sortOrder?: SortOrder;
  status?: StatusType[];
}) => {
  return queryOptions({
    queryKey: ["campaigns", opts],
    queryFn: () => fetchCampaigns(opts),
  });
};

export const useCreateCampaignMutation = () => {
  return useMutation({
    mutationKey: ["campaigns", "create"],
    mutationFn: createCampaign,
    onSuccess: () => queryClient.invalidateQueries(),
    gcTime: 1000 * 10,
  });
};