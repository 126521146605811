import React from "react";

import { createFileRoute, useNavigate, Link } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useMediaQuery } from "usehooks-ts";
import { z } from "zod";

import {
  Button,
  Disclosure,
  Divider,
  FilterChip,
  Icon,
  IconButton,
  LinkBox,
  MultipleComboBox,
  NavigationDrawer,
  Search,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";
import { MdClose, MdTune, MdRefresh } from "@pnpm-monorepo/core/src/ui/icons";
import { cs } from "@pnpm-monorepo/core/src/utils";
import { SmallDateString } from "../../utils/renderers";
import RouterProgress from "../../components/RouterProgress";
import ErrorEmoji from "../../components/ErrorEmoji";
import { PlaceType, SellerType, SortOrder, StatusType } from "../../utils/types";
import { companiesQueryOptions } from "./-api/queryOptions";
import { CompaniesSortBy } from "./-api/companies";
import DefaultPendingComponent from "../../components/PendingComponent";
import AddNewCompany from "./-components/AddNewCompany";
import Pagination from "../../components/Pagination";
import {
  companyStatusesQueryOptions,
  placesQueryOptions,
  sellersQueryOptions,
} from "../../utils/data/common";

const companySearchSchema = z.object({
  page: z.number().catch(1),
  sortBy: z
    .enum([
      "number",
      "name",
      "organizationNumber",
      "place",
      "statusName",
      "updatedAt",
    ])
    .catch("updatedAt"),
  sortOrder: z.enum(["asc", "desc"]).catch("desc"),
  q: z.string().catch(""),
  place: z.number().array().optional(),
  status: z.number().array().optional(),
  seller: z.number().array().optional(),
});

export const Route = createFileRoute("/companies")({
  validateSearch: companySearchSchema,
  loaderDeps: ({
    search: { q, page, sortBy, sortOrder, place, status, seller },
  }) => ({
    q,
    page,
    sortBy,
    sortOrder,
    place,
    status,
    seller,
  }),
  loader: (opts) =>
    opts.context.queryClient.ensureQueryData(companiesQueryOptions(opts.deps)),
  component: CompaniesComponent,
  pendingComponent: DefaultPendingComponent,
});

function CompaniesComponent() {
  const matches = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate({ from: Route.fullPath });
  const { page, sortBy, sortOrder, q, place, status, seller } =
    Route.useSearch();
  const companiesQuery = useSuspenseQuery(
    companiesQueryOptions(Route.useLoaderDeps())
  );

  const companies = companiesQuery.data;

  const placeQuery = useQuery(placesQueryOptions());
  const sellersQuery = useQuery(sellersQueryOptions());
  const companyStatusesQuery = useQuery(companyStatusesQueryOptions());
  const places = placeQuery.data;
  const sellers = sellersQuery.data;
  const companyStatuses = companyStatusesQuery.data;

  const [searchDraft, setSearchDraft] = React.useState(q ?? "");

  const handleSearch = (v: string) => {
    setSearchDraft(v);
  };
  const [selectedPlacesDraft, setSelectedPlacesDraft] = React.useState<
    PlaceType[]
  >([]);
  const [selectedStatusDraft, setSelectedStatusDraft] = React.useState<
    StatusType[]
  >([]);
  const [selectedSellerDraft, setSelectedSellerDraft] = React.useState<
    SellerType[]
  >([]);

  React.useEffect(() => {
    if (places) {
      const p = places.filter((x: PlaceType) => place?.includes(x.id));
      setSelectedPlacesDraft(p);
    }
  }, [place, places]);

  React.useEffect(() => {
    if (companyStatuses) {
      const s = companyStatuses.filter((x: StatusType) => status?.includes(x.id));
      setSelectedStatusDraft(s);
    }
  }, [status, companyStatuses]);

  React.useEffect(() => {
    if (sellers) {
      const p = sellers.filter((x: SellerType) => seller?.includes(x.id));
      setSelectedSellerDraft(p);
    }
  }, [seller, sellers]);

  const setSortBy = (sortBy: CompaniesSortBy) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortBy,
        };
      },
      replace: true,
    });

  const setSortOrder = (sortOrder: SortOrder) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortOrder,
        };
      },
      replace: true,
    });

  const setPage = (page: number) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          page,
        };
      },
      replace: true,
    });

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          q: searchDraft,
          page: 1
        };
      },
      replace: true,
    });
  }, [navigate, searchDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          place: selectedPlacesDraft.map((x: PlaceType) => x.id),
          page: 1
        };
      },
      replace: true,
    });
  }, [navigate, selectedPlacesDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          status: selectedStatusDraft.map((x: StatusType) => x.id),
          page: 1
        };
      },
      replace: true,
    });
  }, [navigate, selectedStatusDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          seller: selectedSellerDraft.map((x: SellerType) => x.id),
          page: 1
        };
      },
      replace: true,
    });
  }, [navigate, selectedSellerDraft]);

  function handleOpenFilter(): void {
    setOpen(!open);
  }

  function handleClearFilter(): void {
    setPage(1);
    setSelectedPlacesDraft([]);
    setSelectedStatusDraft([]);
    setSelectedSellerDraft([]);
  }

  return (
    <div>
      <NavigationDrawer
        open={open}
        variant={matches ? "standard" : "modal"}
        onClose={handleOpenFilter}
      >
        <div className="w-full h-full overflow-auto border-l-[1px] border-outline-variant">
          <div className="p-6 py-5 block mb-16">
            <div className="flex items-center justify-end gap-1">
              <Button
                 disabled={
                  selectedPlacesDraft.length === 0 &&
                  selectedStatusDraft.length === 0 &&
                  selectedSellerDraft.length === 0
                }
                iconLeft={<MdRefresh size={24} />}
                onClick={handleClearFilter}
              >
                Rensa
              </Button>
              <IconButton icon={<MdClose />} onClick={handleOpenFilter} />
            </div>
            <div className="text-title-medium mt-2 py-1">Filter</div>

            <div className="my-8">
            <div className="my-4">
                {places && (
                  <MultipleComboBox
                    items={places}
                    selectedItems={selectedPlacesDraft}
                    setSelectedItems={setSelectedPlacesDraft}
                    placeholder={"Område"}
                  />
                )}
              </div>

              <div className="my-4">
                {companyStatuses && (
                  <MultipleComboBox
                    items={companyStatuses}
                    selectedItems={selectedStatusDraft}
                    setSelectedItems={setSelectedStatusDraft}
                    placeholder={"Status"}
                  />
                )}
              </div>

              <div className="my-4">
                {sellers && (
                  <MultipleComboBox
                    items={sellers}
                    selectedItems={selectedSellerDraft}
                    setSelectedItems={setSelectedSellerDraft}
                    placeholder={"Säljare"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </NavigationDrawer>
      <div
        id="companies-content"
        className={cs(
          "relative transition-all",
          open === true && matches ? "ml-80" : "ml-0"
        )}
      >
        <div className="z-10 flex min-w-[300px] flex-col text-on-surface sticky top-0 bg-surface">
          <div className=" block z-50 pb-3 pt-4 px-4">
            <Search
              id="companies-search"
              name="search"
              placeholder="Sök företag"
              clearOnEscape={true}
              onChange={handleSearch}
              defaultValue={q}
            />

            <div className="mt-4 mb-2 flex gap-2 items-center">
              <div className="flex grow gap-2 items-center">
                <Button
                  variant={open ? "filled" : "outlined"}
                  iconLeft={open ? <MdClose size={20} /> : <MdTune size={20} />}
                  onClick={handleOpenFilter}
                >
                  Filter
                </Button>

                <RouterProgress />
              </div>
              <AddNewCompany />
            </div>
          </div>
        </div>

        {companies?.count === 0 ? (
          <ErrorEmoji message="Hittade inga företag.">
            <Button
              variant="outlined"
              className="mt-4"
              onClick={handleClearFilter}
            >
              Rensa filter och försök igen
            </Button>
          </ErrorEmoji>
        ) : (
          <>
            <Pagination
              maxVisiblePages={10}
              totalItems={companies.count}
              itemsPerPage={50}
              currentPage={page}
              onPageChange={setPage}
            />
            <div className="flex flex-col w-full my-6 px-4 text-body-medium">
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("number");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Nummer{" "}
                          {sortBy === "number" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("name");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Namn{" "}
                          {sortBy === "name" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("organizationNumber");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Organisationsnummer{" "}
                          {sortBy === "organizationNumber" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("place");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Ort{" "}
                          {sortBy === "place" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("statusName");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Status{" "}
                          {sortBy === "statusName" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                      <Table.HeadCell>
                        <button
                          className="flex items-center"
                          onClick={() => {
                            setSortBy("updatedAt");
                            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                          }}
                        >
                          Uppdaterad{" "}
                          {sortBy === "updatedAt" ? (
                            <Icon
                              icon={
                                sortOrder === "asc"
                                  ? "arrow_upward"
                                  : "arrow_downward"
                              }
                              size={18}
                              className="ml-2"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {companies?.results?.map((company) => {
                      return (
                        <Table.Row key={company.id}>
                          <Table.RowCell>
                            <Link
                              to={`/companies/$companyId`}
                              params={(prev) => ({
                                ...prev,
                                companyId: company.id,
                              })}
                              search={true}
                            >
                              <LinkBox>{company.number}</LinkBox>
                            </Link>
                          </Table.RowCell>
                          <Table.RowCell>{company.name}</Table.RowCell>
                          <Table.RowCell>
                            {company.organizationNumber}
                          </Table.RowCell>
                          <Table.RowCell>{company.place}</Table.RowCell>
                          <Table.RowCell>{company.statusName}</Table.RowCell>
                          <Table.RowCell>
                            <SmallDateString value={company.updatedAt} />
                          </Table.RowCell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
              <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
