"use client";
import {
  Dialog as HeadlessDialog,
  DialogProps as HeadlessDialogProps,
} from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { cs } from "../../../utils";
import { MdClose } from "../../icons";
import IconButton from "../IconButton";

import { DialogProps } from "./Dialog.types";

export interface DialogHandle {
  scrollToTop: () => void;
}

const Dialog = forwardRef<DialogHandle, DialogProps>((props, ref) => {
  const {
    fullScreen = false,
    headline,
    text,
    icon,
    actions,
    children,
    open,
    onClose,
  } = props;

  const scrollableRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    scrollToTop() {
      if (scrollableRef.current) {
        scrollableRef.current.scrollTop = 0;
      }
    },
  }));

  useEffect(() => {
    if (open) {
      // Use a small timeout to ensure the element is fully rendered
      setTimeout(() => {
        if (scrollableRef.current) {
          scrollableRef.current.scrollTop = 0;
        }
      }, 10);
    }
  }, [open, scrollableRef.current]);

  return (
    <AnimatePresence>
      {open && (
        <HeadlessDialog
          static
          as={motion.div}
          open={open}
          onClose={onClose}
          className="relative z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

          {/* Full-screen scrollable container */}
          <div
            className="fixed inset-0 w-screen overflow-y-auto"
            ref={scrollableRef}
          >
            {/* Container to center the panel */}
            <div
              className={cs(
                "flex min-h-full items-center justify-center p-4",
                fullScreen && "p-0"
              )}
            >
              {/* The actual dialog panel */}
              <HeadlessDialog.Panel
                className={cs(
                  "mx-auto max-w-[560px] min-w-[280px] w-full rounded-extra-large bg-surface shadow-mm-3 pt-6 flex flex-col gap-4",
                  fullScreen &&
                    "h-screen rounded-none w-screen max-w-none pt-0 bg-surface gap-0 "
                )}
              >
                {fullScreen ? (
                  <>
                    <div className="h-14">
                      <div className="h-14 flex items-center justify-left px-4 text-headline-small text-on-surface gap-2 bg-surface-container-low">
                        <IconButton
                          icon={<MdClose />}
                          onClick={() => onClose(true)}
                        />
                        {headline && <div>{headline}</div>}
                        {actions && (
                          <div className="grow flex items-center justify-end gap-1.5 pl-6">
                            {actions}
                          </div>
                        )}
                      </div>
                    </div>
                    {children && (
                      <div className="px-6 pb-6 flex w-full flex-col overflow-auto gap-4 pt-2">
                        {children}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {icon && (
                      <div className="flex items-center justify-center px-6 text-on-surface">
                        {icon}
                      </div>
                    )}
                    {headline && (
                      <div className="flex items-center justify-center px-6 text-headline-small text-on-surface">
                        {headline}
                      </div>
                    )}
                    {text && (
                      <p className="flex items-center justify-center px-6 text-body-medium text-on-surface-variant">
                        {text}
                      </p>
                    )}

                    {children && (
                      <div className="px-6 mb-6 flex w-full flex-col">
                        {children}
                      </div>
                    )}
                    {actions && (
                      <div className="mb-6 mt-1.5 flex items-center justify-end gap-1.5 px-6">
                        {actions}
                      </div>
                    )}
                  </>
                )}
              </HeadlessDialog.Panel>
            </div>
          </div>
        </HeadlessDialog>
      )}
    </AnimatePresence>
  );
});

export default Dialog;
