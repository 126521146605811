"use client";
import * as React from "react";
import { cs } from "../../../utils";

import IconButton from "../IconButton";
import Icon from "../Icon";
import { SearchProps } from "./Search.types";

const Search: React.FC<SearchProps> = (props) => {
  const {
    className,
    placeholder,
    onChange,
    onKeyDown,
    clearOnEscape = false,
    defaultValue,
    size = "standard",
  } = props;

  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setValue(defaultValue); // Update state when defaultValue changes
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let v = event.target.value;
    setValue(v);

    if (onChange) {
      onChange(v);
    }
  };

  const handleClear = () => {
    setValue("");
    if (onChange) {
      onChange("");
    }
  };

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === "Escape" && clearOnEscape) {
      setValue("");
      if (onChange) {
        onChange("");
      }
    }
  };

  return (
    <div className={cs("relative w-full", className)}>
      <input
        className={cs(
          "flex w-full bg-surface-container-low rounded-extra-large p-4 pl-6 pr-16 text-headline-small placeholder:text-on-surface-variant",
          size === "small" ? "text-title-medium" : ""
        )}
        maxLength={200}
        autoComplete="off"
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleOnKeyDown}
        value={value || defaultValue}
      />
      {value || defaultValue ? (
        <IconButton
          onClick={handleClear}
          className={cs(
            "absolute w-12 h-12 float-right right-5 top-[13px]",
            size === "small" ? "top-[8px] right-3" : ""
          )}
          icon={<Icon icon="close" />}
        />
      ) : null}
    </div>
  );
};

export default Search;
