import { useState } from "react";
import { useAddOffer } from "../-api/queryOptions";
import { Button, Dialog } from "@pnpm-monorepo/core/src/ui/components";
import { MdAdd } from "@pnpm-monorepo/core/src/ui/icons";
import { useNavigate } from "@tanstack/react-router";

const AddOfferDialog = ({ companyId }: { companyId: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate({ from: `/companies/${companyId}/offers` });
  const addOffer = useAddOffer(companyId);

  function handleAddOffer(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleOk(): void {
    addOffer.mutate(
      {
        companyId: companyId,
      },
      {
        onSuccess: (data) => {
          navigate({
            to: "/companies/$companyId/offers/$offerId",
            search: (old) => ({
              ...old,
            }),
            params: { companyId:companyId, offerId: data },
          });
          setOpen(false);
        },
      }
    );
  }

  return (
    <>
      <Button
        iconLeft={<MdAdd size={20} />}
        variant="filled"
        onClick={handleAddOffer}
      >
        Lägg till
      </Button>
      <Dialog
        icon={<MdAdd />}
        open={open}
        onClose={handleClose}
        headline="Lägg till nytt erbjudande?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleOk}
              disabled={addOffer.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};

export default AddOfferDialog;
