import { queryOptions, useMutation } from "@tanstack/react-query";
import { addOffer, fetchCompanyOffers } from "./companyOffers";
import { queryClient } from "../../../../../App";

export const companyOffersQueryOptions = (companyId: number | string) =>
  queryOptions({
    queryKey: ["companies", companyId, "offers"],
    queryFn: () => fetchCompanyOffers(companyId),
  });

  export const useAddOffer = (companyId: number | string) => {
    return useMutation({
      mutationKey: ["companies", "offers", companyId],
      mutationFn: addOffer,
      onSuccess: () => queryClient.invalidateQueries(),
      gcTime: 1000 * 10,
    });
  };