import { useState, useEffect } from "react";
import { Link } from "@tanstack/react-router";

import {
  Icon,
  NavigationRail,
  NavigationRailItem
} from "@pnpm-monorepo/core/src/ui/components";

import {
  MdBusinessCenter,
  MdLoyalty,
  MdCampaign,
  MdEmojiPeople,
} from "@pnpm-monorepo/core/src/ui/icons";

import { cs } from "@pnpm-monorepo/core/src/utils";

// import UserMenu from "./UserMenu";
import MyIcon from "../assets/coupon.svg?react";
import AppMenu from "./AppMenu";
import { useMsal } from "@azure/msal-react";

const Navigation: React.FC = () => {
  const [roles, setRoles] = useState<string[]>([]);
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      const idTokenClaims = account.idTokenClaims;
      const userRoles = idTokenClaims?.roles || [];
      setRoles(userRoles);
    }
  }, [accounts]);

  return (
    <>
      <div className="hidden sm:flex">
        <Nav roles={roles} />
      </div>
    </>
  );
};

const Nav = ({ roles }: { roles: string[] }) => {
  return (
    <NavigationRail
      top={
        <>
          <Link to="/">
            {({ isActive }) => {
              return (
                <div className={cs("nav-identity__link", isActive && "active")}>
                  <span className="nav-identity__icon ">
                    <MyIcon className="dark:fill-on-surface" />
                  </span>
                  <span className="text-label-medium tracking-[.1px] text-center block">
                    Hem
                  </span>
                </div>
              );
            }}
          </Link>
        </>
      }
      center={
        <>
          <Link to="companies">
            {({ isActive }) => {
              return (
                <>
                  <NavigationRailItem
                    id="companies"
                    label="Företag"
                    icon={<MdBusinessCenter />}
                    selected={isActive}
                  />
                </>
              );
            }}
          </Link>
          <Link to="offers">
            {({ isActive }) => {
              return (
                <>
                  <NavigationRailItem
                    id="offers"
                    label="Erbjudanden"
                    icon={<MdLoyalty />}
                    selected={isActive}
                  />
                </>
              );
            }}
          </Link>
          <Link to="campaigns">
            {({ isActive }) => {
              return (
                <>
                  <NavigationRailItem
                    id="campaigns"
                    label="Kampanjer"
                    icon={<MdCampaign />}
                    selected={isActive}
                  />
                </>
              );
            }}
          </Link>
          {roles.includes("Mobilkupongen.Admin") ||
          roles.includes("Mobilkupongen.Writer") ? (
            <>
              <Link to="receivers">
                {({ isActive }) => {
                  return (
                    <>
                      <NavigationRailItem
                        id="receivers"
                        label="Inflyttade"
                        icon={<MdEmojiPeople />}
                        selected={isActive}
                      />
                    </>
                  );
                }}
              </Link>
              <Link to="consumers">
                {({ isActive }) => {
                  return (
                    <>
                      <NavigationRailItem
                        id="consumers"
                        label="Konsumenter"
                        icon={<Icon icon="people" />}
                        selected={isActive}
                      />
                    </>
                  );
                }}
              </Link>
            </>
          ) : undefined}
          {roles.includes("Mobilkupongen.Admin") && (
            <Link to="adminland">
              {({ isActive }) => {
                return (
                  <>
                    <NavigationRailItem
                      id="adminland"
                      label="Adminland"
                      icon={<Icon icon="admin_panel_settings" />}
                      selected={isActive}
                    />
                  </>
                );
              }}
            </Link>
          )}
        </>
      }
      bottom={
        <>
          <AppMenu />
        </>
      }
    ></NavigationRail>
  );
};

export default Navigation;
