
import { Link, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useSnackbar } from "notistack";

import {
  LinkBox,
  Table,
  Tooltip,
  Menu2,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";

import { cs } from "@pnpm-monorepo/core/src/utils";

import { ErrorComponent } from "../../../../components/ErrorComponent";
import { companyOffersQueryOptions } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { SmallDateString, Number } from "../../../../utils/renderers";
import AddOfferDialog from "./-components/AddOfferDialog";
import { companyQueryOptions } from "../-api/queryOptions";
import { CustomHistoryState } from "../../../../utils/types";
import { useUpdateOfferStatusMutation } from "../../../offers_/$offerId/-api/queryOptions";

export const Route = createFileRoute("/companies/$companyId/offers")({
  loader: ({ context: { queryClient }, params: { companyId } }) => {
    queryClient.ensureQueryData(companyQueryOptions(companyId));
    queryClient.ensureQueryData(companyOffersQueryOptions(companyId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CompanyOffersComponent,
});

function CompanyOffersComponent() {
  const { enqueueSnackbar } = useSnackbar();
  const params = Route.useParams();
  const companyQuery = useSuspenseQuery(companyQueryOptions(params.companyId));
  const company = companyQuery.data;
  const companyOffersQuery = useSuspenseQuery(
    companyOffersQueryOptions(params.companyId)
  );
  const companyOffers = companyOffersQuery.data;

  function copyUrlToClipboard() {
    const url = `https://proof.mobilkupongen.se/${company.companyGuid}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        enqueueSnackbar(
          "Publik länk till erbjudanden (korrektur) kopierad till urklipp"
        );
      })
      .catch((err) => {
        console.error("Failed to copy the URL: ", err);
      });
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center gap-2">
        {company.companyGuid && (
          <>
            <a
              href={`https://proof.mobilkupongen.se/${company.companyGuid}`}
              target="_blank"
            >
              <LinkBox>Publik länk till erbjudanden (korrektur) </LinkBox>
            </a>
            <IconButton
              icon={<Icon icon="content_copy" />}
              onClick={copyUrlToClipboard}
            />
          </>
        )}
        <div className="grow" />
        <AddOfferDialog companyId={params.companyId} />
      </div>
      <div className="flex flex-col w-full my-6 text-body-medium">
        {companyOffers.length ? (
          <Table.Wrapper>
            <Table.Root>
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell>Nummer</Table.HeadCell>
                  <Table.HeadCell>Produkt</Table.HeadCell>
                  <Table.HeadCell>Erbjudande</Table.HeadCell>
                  <Table.HeadCell justify="justify-end">
                    Prioritet
                  </Table.HeadCell>
                  <Table.HeadCell justify="justify-end">Öppnade</Table.HeadCell>
                  <Table.HeadCell justify="justify-end">Sparade</Table.HeadCell>
                  <Table.HeadCell justify="justify-end">Inlösta</Table.HeadCell>
                  <Table.HeadCell>Status</Table.HeadCell>
                  <Table.HeadCell>Uppdaterad</Table.HeadCell>
                  <Table.HeadCell></Table.HeadCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {companyOffers?.map((offer) => {
                  return (
                    <Table.Row
                      key={offer.id}
                      className={cs(
                        offer.notVisibleInAppText &&
                          offer.status !== 60 &&
                          "text-on-error-container bg-error-container"
                      )}
                    >
                      <Table.RowCell>
                        <Link
                          to={`/companies/$companyId/offers/$offerId`}
                          params={(prev) => ({
                            ...prev,
                            offerId: offer.id,
                            companyId: params.companyId,
                          })}
                          search={true}
                          state={
                            {
                              from: `/companies/${params.companyId}/offers/${offer.id}`,
                            } as CustomHistoryState
                          }
                        >
                          <LinkBox>{offer.number}</LinkBox>
                        </Link>
                      </Table.RowCell>
                      <Table.RowCell>{offer.productName}</Table.RowCell>
                      <Table.RowCell>
                        {offer.offerWorthText} {offer.offerPrepositionText}{" "}
                        {offer.offerServiceText}
                      </Table.RowCell>
                      <Table.RowCell justify="justify-end">
                        <Number>{offer.priority}</Number>
                      </Table.RowCell>
                      <Table.RowCell justify="justify-end">
                        <Number>{offer.offerViewCount}</Number>
                      </Table.RowCell>
                      <Table.RowCell justify="justify-end">
                        <Number>{offer.offerSavedCount}</Number>
                      </Table.RowCell>
                      <Table.RowCell justify="justify-end">
                        <Number>{offer.offerUsedCount}</Number>
                      </Table.RowCell>
                      <Table.RowCell>
                        <OfferStatusDropdown row={offer} />
                      </Table.RowCell>
                      <Table.RowCell>
                        <SmallDateString value={offer.updatedAt} />
                      </Table.RowCell>
                      <Table.RowCell className="w-16">
                        {offer.notVisibleInAppText && (
                          <Tooltip content={offer.notVisibleInAppText}>
                            <Icon icon="info" color="text-primary" />
                          </Tooltip>
                        )}
                      </Table.RowCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table.Root>
          </Table.Wrapper>
        ) : (
          <div className="p-3 bg-tertiary-container text-center rounded-large">
            Det finns inga erbjudanden.
          </div>
        )}
        <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
      </div>
    </div>
  );
}

const OfferStatusDropdown = ({ row }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateOfferStatus = useUpdateOfferStatusMutation(row.id);

  function handleStatusChange(statusId: number): void {
    const now = new Date(Date.now());

    if (statusId === 60 && row.validFrom != null) {
      if (new Date(row.validFrom) > now) {
        statusId = 50;
      }
    }

    updateOfferStatus.mutate(
      {
        id: row.id,
        statusId: statusId,
      },
      {
        onSuccess: () => {
          if (statusId === 40) {
            if (row.validFrom == null) {
              statusId = 50;
            } else {
              const validFrom = new Date(row.validFrom);

              if (validFrom < now) {
                statusId = 60;
              } else {
                statusId = 50;
              }
            }

            updateOfferStatus.mutate({
              id: row.id,
              statusId: statusId,
            });
          }
        },
        onSettled: () => {
          enqueueSnackbar(`Status uppdaterad (${row.number})`);
        },
      }
    );
  }

  return (
    <Menu2.DropdownMenu>
      <Menu2.DropdownMenuTrigger
        className={cs(
          "outline-none items-center box-content cursor-pointer flex justify-center px-4 h-8 pr-2 hover:shadow-mm-1",
          "rounded-small border-[1px] transition-all duration-300 ease-in-out",
          "text-on-surface-container bg-surface hover:bg-surface-container-low border-outline"
        )}
      >
        <span
          className={cs(
            "text-ellipsis whitespace-nowrap select-none text-label-large",
            "pr-2"
          )}
        >
          {row.statusName}
        </span>
        <div
          className={cs(
            "flex items-center transition-all duration-300 ease-in-out w-[0px]",
            "w-[18px]"
          )}
        >
          <Icon
            icon="arrow_drop_down"
            size={21}
            className="text-on-surface-container"
          />
        </div>
      </Menu2.DropdownMenuTrigger>
      <Menu2.DropdownMenuContent
        align="center"
        forceMount
        className="min-w-40 m-2"
      >
        {row.status === 10 || row.status === 30 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(20)}>
            Klart för godkännande
          </Menu2.DropdownMenuItem>
        ) : null}
        {row.status === 20 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(30)}>
            Avvisa
          </Menu2.DropdownMenuItem>
        ) : null}
        {row.status === 20 || (row.status >= 50 && row.status <= 70) ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(10)}>
            Under uppbyggnad
          </Menu2.DropdownMenuItem>
        ) : null}

        {row.status >= 10 && row.status <= 30 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(40)}>
            Godkänn
          </Menu2.DropdownMenuItem>
        ) : null}

        {row.status === 70 || row.status === 99 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(60)}>
            Aktivera
          </Menu2.DropdownMenuItem>
        ) : null}
        {row.status === 80 || row.status === 90 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(60)}>
            Aktivera
          </Menu2.DropdownMenuItem>
        ) : null}

        {row.status === 50 || row.status === 60 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(70)}>
            Pausa
          </Menu2.DropdownMenuItem>
        ) : null}

        {row.status === 60 || row.status === 70 || row.status === 80 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(90)}>
            Avsluta
          </Menu2.DropdownMenuItem>
        ) : null}

        {row.status >= 50 && row.status <= 70 ? (
          <Menu2.DropdownMenuItem onClick={() => handleStatusChange(99)}>
            Ta bort
          </Menu2.DropdownMenuItem>
        ) : null}
      </Menu2.DropdownMenuContent>
    </Menu2.DropdownMenu>
  );
};
