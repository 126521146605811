import { Button } from "@pnpm-monorepo/core/src/ui/components";
import React, { useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "../utils/canvasPreview";
import { useDebounceEffect } from "../utils/useDebounceEffect";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface ImageUploaderProps {
  onImageUpload: (blob: Blob, image: File) => void;
  isPending: boolean;
  useCrop?: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageUpload,
  isPending,
  useCrop = true,
}) => {
  const [image, setImage] = useState<File | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const thumbnailRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop] = useState<Crop>();
  const [aspect] = useState<number | undefined>(useCrop ? 16 / 9 : undefined);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const selectedImage = acceptedFiles[0];
    setImage(selectedImage);

    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        if (aspect) {
          setCrop(
            centerAspectCrop(img.naturalWidth, img.naturalHeight, aspect)
          );
        } else {
          setCrop(centerAspectCrop(img.naturalWidth, img.naturalHeight, 16 / 9))
        }
      };
    };

    reader.readAsDataURL(selectedImage);
  }, []);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        thumbnailRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, thumbnailRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/png": [".png"],
      "image/svg+xml": [".svg"],
    },
    maxFiles: 1,
    onDrop,
  });

  async function handleImageSave() {
    const imageR = imgRef.current;

    if (!imageR || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = imageR.naturalWidth / imageR.width;
    const scaleY = imageR.naturalHeight / imageR.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    const ctx = offscreen.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    // Draw the image onto the offscreen canvas
    ctx.drawImage(
      imageR,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    // Convert offscreen canvas to Blob
    const blob = await new Promise<Blob | null>((resolve, reject) => {
      offscreen.convertToBlob({ type: image?.type }).then((blob) => {
        resolve(blob);
      }, reject);
    });

    if (!blob) {
      throw new Error("Failed to convert canvas to Blob");
    }

    if (image) {
      onImageUpload(blob, image);
    }
  }

  return (
    <>
      <div
        {...getRootProps()}
        className="cursor-pointer w-full max-w-screen-sm bg-tertiary text-on-tertiary text-center rounded-large min-h-10 p-4"
      >
        <input {...getInputProps()} />

        <p className="text-body-large">
          Dra och släpp en fil här eller klicka för att välja fil
        </p>
        <em className="text-label-medium">
          (Endast *.jpg, *.svg och *.png-bilder kommer att accepteras)
        </em>
      </div>

      {image && (
        <div className="mt-10 w-4/4">
          <ReactCrop
            crop={crop}
            aspect={aspect}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img src={URL.createObjectURL(image)} ref={imgRef} />
          </ReactCrop>
        </div>
      )}

      {/* {!!completedCrop && (
        <div className="flex w-full mt-4 justify-start">
          <div className="overflow-hidden">
            <canvas
              ref={thumbnailRef}
              style={{
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </div>
      )} */}

      {completedCrop && image && (
        <div className="mt-4">
          <Button
            variant="filled"
            onClick={handleImageSave}
            disabled={isPending}
          >
            Spara
          </Button>
        </div>
      )}
    </>
  );
};

export default ImageUploader;
