import api from "../../api";


export const fetchCompanyStatuses = async () => {
  const result = await api
    .get(`/options?type=CompanyStatus`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchOfferStatuses = async () => {
  const result = await api
    .get(`/options?type=OfferStatus`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchChains = async () => {
  const result = await api
    .get(`/options?type=Chain`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchSellers = async () => {
  const result = await api
    .get(`/options?type=Seller`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchIndustries = async () => {
  const result = await api
    .get(`/options?type=Industry`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchPlaces = async () => {
  const result = await api
    .get(`/options?type=Place`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchProducts = async () => {
  const result = await api
    .get(`/options?type=Product`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchContractStatuses = async () => {
  const result = await api
    .get(`/options?type=ContractStatus`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchStudioUsers = async () => {
  const result = await api
    .get(`/options?type=StudioUser`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchCampaignBasisStatuses = async () => {
  const result = await api
    .get(`/options?type=CampaignBasisStatus`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchWeeks = async () => {
  const response = await api
    .get(`/options?type=Week`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return response;
};