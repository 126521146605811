import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  LinkBox,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import RouterProgress from "../../../../components/RouterProgress";
import {
  NoWrap,
  Number,
  SmallDateTimeString,
  YesNo,
} from "../../../../utils/renderers";
import { notificationSchedulerQueryOptions } from "../../../../utils/data/notification";
import EditDialog from "./-components/EditDialog";

export const Route = createFileRoute("/adminland/notifications/scheduler")({
  component: NotificationSchedulerComponent,
});

function NotificationSchedulerComponent() {
  const [searchDraft, setSearchDraft] = React.useState("");
  const { data, isPending } = useQuery(
    notificationSchedulerQueryOptions(searchDraft, "name")
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value;
    setSearchDraft(v);
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="py-4 flex flex-col gap-4 grow">
          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center">
              <div className="flex-1 flex items-center gap-2 lg:flex-row flex-col">
                <div className="flex-1 text-headline-large flex items-center gap-2">
                  <Link to="/adminland">
                    <LinkBox>Adminland</LinkBox>
                  </Link>{" "}
                  / Aviseringsschema <RouterProgress />
                </div>{" "}
                <input
                  type="text"
                  className="p-[4px] bg-surface-container-low rounded-extra-large pl-6 pr-16 text-headline-small placeholder:text-on-surface-variant"
                  placeholder="Sök..."
                  onChange={handleSearch}
                  value={searchDraft}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPending ? (
        <DefaultPendingComponent />
      ) : (
        <>
          <div className="flex  w-full my-6 text-body-medium">
            {data.length ? (
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell>Namn</Table.HeadCell>
                      <Table.HeadCell>Sql</Table.HeadCell>
                      <Table.HeadCell>Push-rubrik</Table.HeadCell>
                      <Table.HeadCell>Push-meddelande</Table.HeadCell>
                      <Table.HeadCell justify="justify-end">
                        Upprepa
                      </Table.HeadCell>
                      <Table.HeadCell>Senast körning</Table.HeadCell>
                      <Table.HeadCell className="border-r-0">
                        Aktiv
                      </Table.HeadCell>
                      <Table.HeadCell className="sticky right-0 bg-surface-container-highest shadow-sticky-left"></Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {data?.map((obj) => {
                      return (
                        <Table.Row key={obj.id}>
                          <Table.RowCell>{obj.executer?.name}</Table.RowCell>
                          <Table.RowCell>
                            {obj.executer?.receiverSql}
                          </Table.RowCell>
                          <Table.RowCell>
                            {obj.executer?.content?.push_Title}
                          </Table.RowCell>
                          <Table.RowCell>
                            {obj.executer?.content?.push_Body}
                          </Table.RowCell>
                          <Table.RowCell justify="justify-end">
                            <Number>{obj.repeatMinutes}</Number>
                          </Table.RowCell>
                          <Table.RowCell>
                            <NoWrap>
                              <SmallDateTimeString value={obj.lastRepeat} />
                            </NoWrap>
                          </Table.RowCell>
                          <Table.RowCell className="border-r-0">
                            <YesNo value={obj.active} />
                          </Table.RowCell>
                          <Table.RowCell
                            justify="justify-center"
                            className=" sticky right-0 bg-surface overflow-hidden shadow-sticky-left w-16"
                          >
                            <EditDialog
                              schedule={obj}
                            />
                          </Table.RowCell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
            ) : (
              <div className="p-3 bg-tertiary-container text-on-tertiary-container mt-6 text-center rounded-large text-body-large">
                Hittade inte några erbjudandetjänster.
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}